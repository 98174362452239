<div class="container-fluid p-0 dashboard-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <div class="row mb-xl-3 justify-content-between">
          <div class="col-auto">
            <h3 class="page-title">Settings</h3>
          </div>
        </div>

        <ng-container
          *ngFor="let settings of settings; let settingIndex = index"
        >
          <div class="row">
            <ng-container *ngFor="let column of settings.columns">
              <div class="{{ column.className }}">
                <div class="card flex-fill border-0">
                  <div class="card-header">
                    <h5 class="text-md">{{ column.name }}</h5>
                  </div>
                  <div class="card-body rounded px-4 pt-3 pb-0 bg-white">
                    <ul>
                      <li *ngFor="let link of column.links">
                        <a
                          [routerLink]="link.link"
                          class="cursor-pointer primary-text font-weight-semibold"
                          >{{ link.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
