<div class="container-fluid p-0 dashboard-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <div class="row mb-xl-3 justify-content-between">
          <div class="col-auto">
            <h3 class="page-title">Dashboard</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <div class="card flex-fill border-0 bg-grey">
              <div class="card-header rounded pb-0 bg-white border-0">
                <div class="row mb-4">
                  <div class="col-1"></div>
                  <div class="col-3">
                    <select name="case" id="case" class="form-control p-1">
                      <option value="" disabled selected>Select</option>
                      <option value="" disabled>Case Name</option>
                      <option value="" disabled>Case Number</option>
                      <option value="" disabled>Client Reference #</option>
                      <option value="" disabled>Insured</option>
                      <option value="" disabled>Policy #</option>
                    </select>
                  </div>
                  <div class="col-4 d-flex">
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Enter text"
                    />
                    <button type="button" class="btn btn-sm btn-primary">
                      Search
                    </button>
                  </div>
                  <div class="col-2 text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-info p-1 w-100 h-100"
                      (click)="openModal(createNewCase, 'xl')"
                    >
                      New Case
                    </button>
                  </div>
                  <div class="col-2 text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary p-1 w-100 h-100"
                      (click)="openModal(createNewProgression, 'md')"
                    >
                      New Progression
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 primary-tab">
                    <!-- <h3 class="page-title d-inline-block mr-4">
                                    Search by
                                </h3>
                                <select (change)="searchBy($event)" [(ngModel)]="searchByInVal"
                                    class="form-control d-inline-block w-auto">
                                    <option value="byspear">Spear</option>
                                    <option value="bylimos">LIMOS</option>
                                </select> -->

                    <ul class="nav nav-tabs">
                      <li class="nav-item text-black-contrast">
                        <button
                          class="active nav-link text-uppercase"
                          [ngClass]="{
                            'search-spear-background':
                              activeDepartmentTab == 'Company'
                          }"
                          (click)="
                            activeDepartmentTab = 'Company';
                            getDepartmentProgressions()
                          "
                        >
                          Company
                        </button>
                      </li>
                      <li
                        class="nav-item text-black-contrast"
                        *ngFor="let tab of departmentNames"
                      >
                        <button
                          class="active nav-link text-uppercase"
                          [ngClass]="{
                            'search-spear-background':
                              activeDepartmentTab == tab.name
                          }"
                          (click)="
                            activeDepartmentTab = tab.name;
                            getDepartmentProgressions(tab.id)
                          "
                        >
                          {{ tab.name }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-body search-spear-background department-card">
                <div class="table-row" *blockUI="'department-card-ui'">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">DEPARTMENTS</th>
                        <ng-container *ngFor="let column of departmentColumns">
                          <th class="border-top-0" scope="col">
                            {{ column.name }}
                          </th>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="progressions?.length">
                        <tr *ngFor="let row of progressions">
                          <td>
                            <span class="text-black-contrast">{{
                              row.name
                            }}</span>
                          </td>
                          <td *ngFor="let column of departmentColumns">#</td>
                        </tr>
                      </ng-container>

                      <tr
                        *ngIf="
                          !progressions?.length && departmentColumns?.length
                        "
                      >
                        <td
                          class="text-center"
                          [attr.colspan]="departmentColumns?.length + 1"
                        >
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card flex-fill border-0">
              <div class="card-header">
                <h5>Recent Views</h5>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">Case Number</th>
                        <th class="border-top-0" scope="col">Name Of Case</th>
                        <th class="border-top-0" scope="col">Case Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="3" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8" *blockUI="'diaries-list-ui'">
            <div class="card flex-fill border-0 mt-3">
              <div class="card-header">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a
                      class="nav-link active text-sm font-weight-bold text-dark"
                      data-toggle="pill"
                      href="#home"
                      (click)="setCaseDtlsColumns('Diaries')"
                      >Diaries</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link text-sm font-weight-bold text-dark"
                      data-toggle="pill"
                      href="#menu2"
                      (click)="setCaseDtlsColumns('Assigned Cases')"
                      >Assigned Cases</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="text-right" *ngIf="selectedCaseTab == 'Diaries'">
                  <input
                    type="checkbox"
                    name="all_diaries"
                    id="all_diaries"
                    [checked]="false"
                    (change)="checkAllDiaries($event.target.checked)"
                  />
                  <label
                    for="all_diaries"
                    class="text-sm text-black-contrast pl-1"
                    >All Diaries</label
                  >
                </div>
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th
                          *ngFor="let column of caseDtlsColumns"
                          class="border-top-0"
                          scope="col"
                        >
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <ng-container>
                      <tbody
                        *ngIf="selectedCaseTab == 'Diaries' && diaries?.length"
                      >
                        <tr class="bg-white" *ngFor="let diary of diaries">
                          <td>{{ diary.case_number }}</td>
                          <td>{{ diary.case_name }}</td>
                          <td>{{ diary.type }}</td>
                          <td>{{ diary.note }}</td>
                          <td>
                            {{ diary.due_date | date : clientDateFormat }}
                          </td>
                        </tr>
                      </tbody>
                    </ng-container>

                    <tbody *ngIf="selectedCaseTab == 'Assigned Cases'"></tbody>
                    <tbody *ngIf="!diaries?.length">
                      <tr>
                        <td
                          [attr.colspan]="caseDtlsColumns.length"
                          class="text-center"
                        >
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="px-0 py-2 paginationWrapper spearPaginationWrapper d-flex"
                    *ngIf="selectedCaseTab == 'Diaries' && diaries?.length"
                  >
                    <ngb-pagination
                      [(collectionSize)]="diaryTotal"
                      [(page)]="diaryPage"
                      [pageSize]="diaryLimit"
                      [maxSize]="10"
                      [boundaryLinks]="true"
                      (pageChange)="onPageChangeDiaries($event)"
                    >
                    </ngb-pagination>

                    <div class="selected d-flex align-items-center">
                      <select
                        (change)="onSizeChangeDiaries($event.target.value)"
                        class="custom-select table-select h-100"
                        [(ngModel)]="diaryLimit"
                      >
                        <option [value]="30">30</option>
                        <option [value]="50">50</option>
                        <option [value]="75">75</option>
                        <option [value]="100">100</option>
                      </select>
                      <p class="mb-0 ml-2 text-nowrap">
                        Showing
                        {{ diaryPage * diaryLimit - diaryLimit + 1 }} -
                        {{
                          diaryPage * diaryLimit > diaryTotal
                            ? diaryTotal
                            : diaryPage * diaryLimit
                        }}
                        of {{ diaryTotal }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card flex-fill border-0">
              <div class="card-header">
                <h5>Diary Outlook</h5>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">Due Today</th>
                        <th class="border-top-0" scope="col">Due Tomorrow</th>
                        <th class="border-top-0" scope="col">Due This Week</th>
                        <th class="border-top-0" scope="col">Past Due</th>
                        <th class="border-top-0" scope="col">No Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="5" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createNewCase let-modal>
  <div class="modal-header">
    <h6>New Case</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-new-case text-sm">
    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="caseCreatedOn"
            >Created On</span
          >
          <input
            type="text"
            name="caseCreatedOn"
            id="caseCreatedOn"
            class="form-control"
            [value]="todayDate | date : 'MM/dd/yyyy'"
            jDate
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="caseDueDate">Due Date</span>
          <input
            type="text"
            name="caseDueDate"
            id="caseDueDate"
            class="form-control"
            jDate
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_client">Client</span>
          <select name="case_client" id="case_client" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_program">Program</span>
          <select name="case_program" id="case_program" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_tpa">TPA</span>
          <select name="case_tpa" id="case_tpa" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_mga">MGA</span>
          <select name="case_mga" id="case_mga" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_contact"
            >Case Contact</span
          >
          <select name="case_contact" id="case_contact" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_client_contact"
            >Client Contact</span
          >
          <select
            name="case_client_contact"
            id="case_client_contact"
            class="form-control"
          >
            <option value="">Select</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_type">Case Type</span>
          <select name="case_type" id="case_type" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_status"
            >Client Status</span
          >
          <select name="case_status" id="case_status" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_client_reference_no"
            >Client Reference #</span
          >
          <input
            type="text"
            name="case_client_reference_no"
            id="case_client_reference_no"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_name">Case Name</span>
          <input
            type="text"
            name="case_name"
            id="case_name"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_insured">Insured</span>
          <input
            type="text"
            name="case_insured"
            id="case_insured"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_location"
            >Case Location</span
          >
          <input
            type="text"
            name="case_location"
            id="case_location"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_claim_status"
            >Claim Status</span
          >
          <input
            type="text"
            name="case_claim_status"
            id="case_claim_status"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_adj_no">ADJ #</span>
          <input
            type="text"
            name="case_adj_no"
            id="case_adj_no"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div>
      <div class="mt-4 d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          [routerLink]="['/cases']"
          (click)="modalService.dismissAll()"
        >
          Save & Continue
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createNewProgression let-modal>
  <div class="modal-header">
    <h6>New Progression</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-new-case text-sm">
    <form [formGroup]="progressionForm">
      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1 required" for="caseCreatedOn"
              >Name</span
            >
            <input
              type="text"
              name="name"
              id="progression_name"
              class="form-control"
              formControlName="name"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="color">Color</span>
            <input
              type="text"
              name="color"
              id="progression_color"
              class="form-control"
              formControlName="color"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="department"
              >Department</span
            >
            <select
              name="department"
              id="department"
              class="form-control"
              formControlName="department"
            >
              <option
                [value]="department.id"
                *ngFor="let department of departmentNames"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="rank">Rank</span>
            <select
              name="rank"
              id="rank"
              class="form-control text-capitalize"
              formControlName="rank"
            >
              <option
                [value]="rank"
                *ngFor="let rank of ranks"
                class="text-capitalize"
              >
                {{ rank }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 mr-1" for="notes">Notes</span>
            <textarea
              name="notes"
              id="notes"
              class="border w-100 rounded"
              formControlName="notes"
            >
            </textarea>
          </div>
        </div>
      </div>

      <div>
        <div class="mt-4 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="modalService.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="onSubmitdepartmentProgressionForm()"
            [disabled]="progressionForm.invalid"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
