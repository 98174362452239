import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CurrentUserProfile } from 'src/app/models/account/current.user.profile';
import {
  DepartmentColumn,
  DepartmentCreateResponse,
  DepartmentDataResponse,
  DepartmentName,
  DepartmentPaginatedData,
  Progression,
} from 'src/app/models/department/department.interface';
import {
  Diary,
  LimosDataResponse,
  PaginationData,
} from 'src/app/models/limos/limos.models';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { AccountService } from 'src/app/services/account.service';
import { DepartmentService } from 'src/app/services/department.service';
import { LimosService } from 'src/app/services/limos.service';
import { ProviderPagedResult } from './../../models/provider/provider.paged.result';
import { RecordCount } from './../../models/provider/records.count';
import { ProviderService } from './../../services/provider.service';
import { AppUtils } from './../../utilities/app.utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  todayDate: Date = new Date();

  providers: ProviderPagedResult;
  recordsCount: RecordCount;
  providerfilterModel: ProviderFilterModel;
  searchText: string;
  searchCriteria: string[];
  page: number;
  pageSize: number;
  orderBy: string;
  isDescending: boolean;
  omitEmptyNames: boolean;
  showDublicateName: boolean;
  showNewRecords: boolean;
  providerType: number;
  SPRcount: any;
  caseDtls = [
    {
      tab: 'Diaries',
      columns: [
        'Case Number',
        'Name of Case',
        'Diary Type',
        'Diary Note',
        'Due Date',
      ],
    },
    {
      tab: 'Assigned Cases',
      columns: [
        'Case Number',
        'Name of Case',
        'Case Status',
        'Client',
        'Last Updated',
      ],
    },
  ];
  departmentLimit = 20;
  departmentPage = 1;
  departmentTotal: number;
  departmentNames: DepartmentName[];

  caseDtlsColumns = this.caseDtls[0].columns;
  private unsubscribe$ = new Subject<void>();
  @BlockUI('dashboard-screen') blockUI: NgBlockUI;
  @BlockUI('diaries-list-ui') public diariesBlockUI: NgBlockUI;

  diaryLimit = 30;
  diaryPage = 1;
  diaryTotal: number;
  diaries: Diary[] = [];
  selectedCaseTab: string;
  departmentColumns: DepartmentColumn[];
  departmentColumnTotal: number;
  @BlockUI('department-card-ui') departmentCardBlockUI: NgBlockUI;
  userProfile: CurrentUserProfile;
  activeDepartmentTab = 'Company';
  departmentTabs = [];
  ranks = [
    'unassigned cases',
    'open cases',
    'editing',
    'quality control',
    'case monitoring',
    'web preserve',
    'invoicing',
    'etc',
    'totals',
    'video processing',
    'review',
    'monitoring',
    'case setup',
    'level I',
    'level II',
    'level III',
    'D/R In Progress',
    'level IV',
    'level V',
    'level VI',
    'liens only',
    'provider review',
    'product active',
    'pending auth',
    'setup',
    'active cases',
    'follow up',
    'hold',
  ];
  progressionForm: FormGroup;
  isdepartmentProgressionFormSubmitted: boolean;
  editDepartmentProgressionData: any;
  progressions: Progression[];
  departmentProgressionTotal: number;

  constructor(
    private providersService: ProviderService,
    private appUtils: AppUtils,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private limosService: LimosService,
    private departmentService: DepartmentService,
    private accountService: AccountService
  ) {
    this.searchText = '';
    this.searchCriteria = [];
    this.page = 1;
    this.pageSize = 7;
    // this.orderBy = ProviderListHeadersEnum.createdDate as any;
    this.isDescending = false;
    this.omitEmptyNames = false;
    this.showDublicateName = false;
    this.showNewRecords = true;
    this.providerType = 1;
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.providers = new ProviderPagedResult();
    this.recordsCount = new RecordCount();
    this.providerfilterModel = new ProviderFilterModel(
      this.searchText,
      this.searchCriteria,
      this.page,
      this.pageSize,
      this.orderBy,
      this.isDescending,
      this.omitEmptyNames,
      this.showDublicateName,
      this.showNewRecords,
      this.providerType
    );
    //this.getRecordsCount();
    this.getProvidersPagedList();
    this.getSPRCount();
    this.setCaseDtlsColumns('Diaries');
    this.getDepartmentData();
    this.initProgressionForm();
  }

  initProgressionForm() {
    this.progressionForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl(''),
      department: new FormControl(null),
      notes: new FormControl(''),
      rank: new FormControl(''),
    });
  }

  getUserInfo() {
    this.accountService.$userInfo
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((userInfo: CurrentUserProfile) => {
        this.getDiaries(userInfo?.details?.id);
      });
  }

  getDepartmentData() {
    const $departmentNames = this.departmentService.getDepartmentNames(
      this.departmentLimit,
      this.departmentPage
    );

    const $departmentColumns = this.departmentService.getDepartmentColumns(
      this.departmentLimit,
      this.departmentPage
    );

    const $departmentProgressions =
      this.departmentService.getDepartmentProgressions(
        this.departmentLimit,
        this.departmentPage
      );

    this.departmentCardBlockUI.start();
    forkJoin([$departmentNames, $departmentColumns, $departmentProgressions])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          departmentNamesResp,
          departmentColumnsResp,
          departmentProgressionResp,
        ]: [
          DepartmentDataResponse<DepartmentPaginatedData<DepartmentName>>,
          DepartmentDataResponse<DepartmentPaginatedData<DepartmentColumn>>,
          DepartmentDataResponse<DepartmentPaginatedData<Progression>>
        ]) => {
          const {
            hits: departmentColumnHits,
            estimatedTotalHits: departmentColumnEstimatedTotalHits,
          } = departmentColumnsResp.details;
          this.departmentColumns = departmentColumnHits;
          this.departmentColumnTotal = departmentColumnEstimatedTotalHits;

          const {
            hits: departmentNameHits,
            estimatedTotalHits: departmentNameEstimatedTotalHits,
          } = departmentNamesResp.details;
          this.departmentNames = departmentNameHits;
          this.departmentTotal = departmentNameEstimatedTotalHits;

          const {
            hits: departmentProgressionHits,
            estimatedTotalHits: departmentProgressionEstimatedTotalHits,
          } = departmentProgressionResp.details;
          this.progressions = departmentProgressionHits;
          this.departmentProgressionTotal =
            departmentProgressionEstimatedTotalHits;
          this.departmentCardBlockUI.stop();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.success(error?.errors[0]);
          this.departmentCardBlockUI.stop();
        }
      );
  }

  getDepartmentProgressions(departmentId = null) {
    this.departmentCardBlockUI.start();
    this.departmentService
      .getDepartmentProgressions(
        this.departmentLimit,
        this.departmentPage,
        departmentId
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (
          resp: DepartmentDataResponse<DepartmentPaginatedData<Progression>>
        ) => {
          const { hits, offset, estimatedTotalHits } = resp.details;
          this.progressions = hits;
          this.departmentTotal = estimatedTotalHits;
          this.departmentCardBlockUI.stop();
        },
        (error) => {
          this.departmentCardBlockUI.stop();
        }
      );
  }

  onPageChangedepartments(event: any) {
    this.departmentPage = event;
    this.getDepartmentData();
  }

  onSizeChangedepartments(limit: number) {
    this.departmentLimit = limit;
    this.departmentPage = 1;
    this.getDepartmentData();
  }

  getProvidersPagedList = (): void => {
    this.blockUI.start();
    this.providersService.getProviders(this.providerfilterModel).subscribe(
      (data) => {
        this.providers = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };
  getRecordsCount = () => {
    this.blockUI.start();
    this.providersService.getRecordsCount().subscribe(
      (data) => {
        this.recordsCount = data;
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  getSPRCount = () => {
    this.blockUI.start();
    this.providersService.getSprCount('SPR').subscribe(
      (data) => {
        this.SPRcount = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  setCaseDtlsColumns(tab: string) {
    this.selectedCaseTab = tab;
    this.caseDtlsColumns = this.caseDtls.find((dtl) => dtl.tab == tab).columns;
  }

  getDiaries(userId = null) {
    this.diariesBlockUI.start();
    this.limosService
      .getDiaries(this.diaryLimit, this.diaryPage, userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<PaginationData<Diary>>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.diaries = hits;
          this.diaryTotal = estimatedTotalHits;
          this.diariesBlockUI.stop();
        },
        (error) => {
          console.error('Error fetching cases:', error);
          this.diariesBlockUI.stop();
        }
      );
  }

  onPageChangeDiaries(event: any) {
    this.diaryPage = event;
    this.getDiaries();
  }

  onSizeChangeDiaries(limit: number) {
    this.diaryLimit = limit;
    this.diaryPage = 1;
    this.getDiaries();
  }

  checkAllDiaries(value: boolean) {
    if (value) {
      this.getDiaries();
    } else {
      this.getDiaries(this.accountService.userInfo?.details?.id);
    }
  }

  onSubmitdepartmentProgressionForm() {
    if (this.progressionForm.invalid) {
      return;
    }

    this.isdepartmentProgressionFormSubmitted = true;

    if (this.editDepartmentProgressionData?.id) {
      this.updateDepartmentProgression();
    } else {
      this.saveDepartmentProgression();
    }
  }

  saveDepartmentProgression() {
    this.departmentService
      .createDepartmentProgression(this.progressionForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.progressionForm.reset();
          this.isdepartmentProgressionFormSubmitted = false;
          this.getDepartmentData();
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  updateDepartmentProgression() {
    this.departmentService
      .updateDepartmentName(
        this.editDepartmentProgressionData.id,
        this.progressionForm.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.progressionForm.reset();
          this.isdepartmentProgressionFormSubmitted = false;
          this.getDepartmentData();
          this.editDepartmentProgressionData = null;
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
