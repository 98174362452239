import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CurrentUserProfile } from 'src/app/models/account/current.user.profile';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { ProviderPagedResult } from 'src/app/models/provider/provider.paged.result';
import { RecordCount } from 'src/app/models/provider/records.count';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProviderService } from 'src/app/services/provider.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { SearchCriteria } from 'src/app/utilities/enums';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
})
export class TopBarComponent implements OnInit, OnDestroy {
  //for selection ng-select
  selectedCriteria: string[];
  searchCriteriaSelectListItem: string[];
  searchCriteria = SearchCriteria;

  closeResult = '';

  @ViewChild('downloadLink') downloadLink: ElementRef;
  h2c: any = html2canvas;
  recordsCount: any;
  providers: any;
  providerfilterModel: ProviderFilterModel;
  searchTexts: string;
  searchCriterias: string[];
  page: number;
  pageSize: number;
  orderBy: string;
  isDescending: boolean;
  omitEmptyNames: boolean;
  showDublicateName: boolean;
  showNewRecords: boolean;
  providerType: number;
  uniqueRecords: string;
  totalSource: string;
  sprCount: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private accountService: AccountService,
    private modalService: ModalService,
    public providerService: ProviderService,
    private appUtils: AppUtils,
    private toastr: ToastrService
  ) {
    this.searchText = '';
    this.searchCriterias = [];
    this.page = 1;
    this.pageSize = 7;
    // this.orderBy = ProviderListHeadersEnum.createdDate as any;
    this.isDescending = false;
    this.omitEmptyNames = false;
    this.showDublicateName = false;
    this.showNewRecords = true;
    this.providerType = 1;
    this.searchCriteriaSelectListItem = Object.keys(this.searchCriteria).filter(
      (k) => !isNaN(Number(k))
    );
    this.uniqueRecords = '...';
    this.totalSource = '...';
    this.sprCount = '...';
  }
  userProfile: CurrentUserProfile;
  searchText: string;
  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.selectedCriteria = [SearchCriteria.Name.toString()];
    this.searchText = '';
    this.subscriptions = [];
    this.userProfile = new CurrentUserProfile();
    this.initSubscribers();
    this.providers = new ProviderPagedResult();
    this.recordsCount = new RecordCount();
    this.providerfilterModel = new ProviderFilterModel(
      this.searchTexts,
      this.searchCriterias,
      this.page,
      this.pageSize,
      this.orderBy,
      this.isDescending,
      this.omitEmptyNames,
      this.showDublicateName,
      this.showNewRecords,
      this.providerType
    );
    this.getProvidersPagedList();
  }

  initSubscribers(): void {
    this.accountService.getCurrentUserProfile().subscribe(
      (data: CurrentUserProfile) => {
        this.userProfile = data as CurrentUserProfile;
        this.accountService.userInfo = this.userProfile;
      },
      (error) => {
        if (error.status == 0 || error.status === 401) {
          this.authService.clearStorage();
          this.router.navigate(['/login']);
        }
      }
    );

    const subsriptionResetSearchbar = this.providerService
      .listenToResetSearchBar()
      .subscribe(() => {
        this.searchText = '';
      });
    this.subscriptions.push(subsriptionResetSearchbar);
  }

  captureScreen(): void {
    this.h2c(document.body).then((canvas) => {
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'Screenshot.png';
      this.downloadLink.nativeElement.click();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }

  onChangePasswordClick(): void {
    this.modalService.openModalForChangePassword();
  }

  open(content): void {
    // this.addmodalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true})
    // .result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  searchProviders(): void {
    // if(this.selectedCriteria.length === 0){
    //   this.appUtils.ProcessCustomResponse(this.toastr, true, 'Please select a search criteria');
    //   return;
    // }
    this.providerService.notifyToSearchRecords(
      this.searchText,
      this.selectedCriteria
    );
  }

  backToProvider(): void {
    this.router.navigate(['/']);
  }

  // dashboard stats
  getProvidersPagedList(): void {
    this.providerService
      .getProviders(this.providerfilterModel)
      .subscribe((data) => {
        if (data) {
          this.providers = data;
          this.uniqueRecords = this.providers?.details?.profiles_count
            ? this.providers?.details?.profiles_count.toString()
            : 'No Record Found';
          this.totalSource = this.providers?.details?.total_data_sources
            ? this.providers?.details?.total_data_sources.toString()
            : 'No Record Found';
          this.sprCount = this.providers?.details?.spr_profiles_count
            ? this.providers?.details?.spr_profiles_count.toString()
            : 'No Record Found';
        }
      });
  }
}
