<div class="container-fluid p-0 limos-wrapper">
  <div class="limos row">
    <div class="col-12 primary-tab">
      <ul class="nav nav-tabs">
        <li class="nav-item" *ngFor="let tab of pageTabs">
          <button
            class="nav-link text-uppercase"
            [ngClass]="{ 'active nav-link.active': activeTab === tab }"
            (click)="setActiveTab(tab)"
          >
            {{ tab }}
          </button>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="activeTab == 'Dashboard'">
      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div
              class="mb-1 position-relative text-center d-flex justify-content-between"
            >
              <div class="open-close-limos">
                <span>LIMOS Status</span>
                <select
                  class="form-control"
                  (change)="choseLimosStatus($event, limosStatusModal)"
                  c
                  [formControl]="limosStatus"
                >
                  <option value="limos_status_open">Open</option>
                  <option value="limos_status_close">Closed</option>
                </select>
                <button
                  class="btn btn-sm bg-voilet limos-button-background text-white px-1 py-1 pr-1 pl-1 ml-3 rounded"
                  [disabled]="!limos_status"
                  (click)="updateLimosStatus(limosStatusModalSuccess)"
                >
                  Submit
                </button>
              </div>
              <h3 class="page-title" *ngIf="claimTable">
                LIMOS &#35;{{ claimTable.id }}
              </h3>
              <div class="text-right">
                <button
                  class="btn btn-sm spear-button-background text-uppercase text-white px-2 py-1 pr-2 pl-2"
                >
                  Spear
                </button>
                <button
                  class="btn btn-sm limos-button-background text-uppercase text-white px-2 py-1 pr-2 pl-2 ml-2"
                >
                  LIMOS
                </button>
                <button
                  class="btn btn-sm adonis-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Adonis
                </button>
                <button
                  class="btn btn-sm urania-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Urania
                </button>
                <button
                  class="btn btn-sm midas-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Midas
                </button>
                <button
                  routerLink="/reports"
                  class="btn btn-sm achilles-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Achilles
                </button>
                <button
                  class="btn btn-sm bg-voilet limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                  (click)="exportToPdf()"
                >
                  <em class="fa fas fa-angle-double-right mr-3"></em>Export
                </button>
              </div>
            </div>
            <div id="limosPage">
              <div *blockUI="'manage-users-blockUI'">
                <div class="row">
                  <div class="col-12">
                    <div class="table-row">
                      <form name="adminForm" [formGroup]="adminForm">
                        <table
                          class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl"
                        >
                          <caption>
                            Data Table
                          </caption>
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">CLAIM &#35;</th>
                              <th class="border-0" scope="col">ADJ &#35;</th>
                              <th class="border-0" scope="col">
                                CLAIMANT NAME
                              </th>
                              <th class="border-0" scope="col">
                                TPA/ADMINISTRATOR
                              </th>
                              <th class="border-0" scope="col">CARRIER</th>
                              <th class="border-0" scope="col">CLIENT</th>

                              <th class="border-0" scope="col">CLAIM STATUS</th>
                              <th class="border-0" scope="col">
                                CLAIM SETTLED BY
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white">
                              <td>{{ claimTable?.claimNo || "Not Listed" }}</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  value="{{ claimTable?.adjNo }}"
                                  id="adjNo"
                                  formControlName="adj_number"
                                  disabled
                                />
                              </td>
                              <td>
                                {{
                                  claimTable?.claimant_name
                                    ? (claimTable?.claimant_name
                                      | formatclaimant)
                                    : "Not Listed"
                                }}
                              </td>
                              <td class="text-uppercase">Sedgwick</td>
                              <td>{{ claimTable?.carrier || "Not Listed" }}</td>
                              <td>{{ claimTable?.client || "Not Listed" }}</td>

                              <td>
                                {{ claimTable?.claim_status || "Not Listed" }}
                              </td>
                              <td>
                                <select
                                  class="form-control"
                                  formControlName="claimSettledBy"
                                  id="claimSettledBy"
                                  disabled
                                >
                                  <option value="">Select and option</option>
                                  <option
                                    [selected]="
                                      claimTable?.claimSettledBy ===
                                      'Compromise & Release'
                                    "
                                    value="Compromise & Release"
                                  >
                                    Compromise & Release
                                  </option>
                                  <option
                                    [selected]="
                                      claimTable?.claimSettledBy === 'Dismissal'
                                    "
                                    value="Dismissal"
                                  >
                                    Dismissal
                                  </option>
                                  <option
                                    [selected]="
                                      claimTable?.claimSettledBy ===
                                      'Findings & Award'
                                    "
                                    value="Findings & Award"
                                  >
                                    Findings & Award
                                  </option>
                                  <option
                                    [selected]="
                                      claimTable?.claimSettledBy ===
                                      'Stipulation'
                                    "
                                    value="Stipulation"
                                  >
                                    Stipulation
                                  </option>
                                  <option
                                    [selected]="
                                      claimTable?.claimSettledBy ===
                                      'Take Nothing'
                                    "
                                    value="Take Nothing"
                                  >
                                    Take Nothing
                                  </option>
                                </select>
                              </td>
                              <td>
                                <div class="d-flex">
                                  <button
                                    class="icon-button mr-2"
                                    id="enable-edit-admin-save"
                                    (click)="
                                      enableElements(
                                        ['adjNo', 'claimSettledBy'],
                                        ['enable-edit-admin-save']
                                      )
                                    "
                                  >
                                    <em class="fa fas fa-pencil"></em>
                                  </button>
                                  <button
                                    [disabled]="
                                      !emptyadminForm || adminForm.invalid
                                    "
                                    class="icon-button mr-2"
                                    (click)="adminSave()"
                                  >
                                    <em class="fa fas fa-check"></em>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </form>
                    </div>

                    <div class="container-flex row">
                      <div class="col-md-4">
                        <div class="table-row hearing">
                          <form name="adjForm1" [formGroup]="adjForm1">
                            <table
                              class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl mb-0"
                            >
                              <caption>
                                Adjuster Table 1
                              </caption>
                              <thead>
                                <tr>
                                  <th class="border-0" scope="col">
                                    <b>DATE</b>
                                  </th>
                                  <th class="border-0" scope="col">TIME</th>
                                  <th class="border-0" scope="col">VENUE</th>
                                  <th class="border-0" scope="col">JUDGE</th>
                                  <th class="border-0" scope="col">OUTCOME</th>
                                  <th class="border-0" scope="col">ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bg-white">
                                  <td>{{ limosTable?.lien_hearing_date }}</td>
                                  <td>{{ limosTable?.lien_hearing_time }}</td>
                                  <td>{{ limosTable?.wcab_venue }}</td>
                                  <td>{{ limosTable?.wcab_judge }}</td>
                                  <td>{{ claimTable?.wcab_outcome }}</td>
                                  <td>
                                    <div class="d-flex">
                                      <button
                                        class="icon-button mr-2"
                                        id="enable-edit-adjuster-save1"
                                      >
                                        <em class="fa fas fa-pencil"></em>
                                      </button>
                                      <button
                                        [disabled]="
                                          !emptyAdjForm1 || adjForm1.invalid
                                        "
                                        class="icon-button mr-2"
                                      >
                                        <em class="fa fas fa-check"></em>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>

                      <div class="col-md-8">
                        <div class="table-row">
                          <form name="adjForm1" [formGroup]="adjForm1">
                            <table
                              class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl mb-0"
                            >
                              <caption>
                                Adjuster Table 2
                              </caption>
                              <thead>
                                <tr>
                                  <th class="border-0" scope="col">
                                    <b>ADJUSTER</b>
                                  </th>
                                  <th class="border-0" scope="col">PHONE:</th>
                                  <th class="border-0" scope="col">EMAIL:</th>
                                  <th class="border-0" scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bg-white">
                                  <td style="width: 40%">
                                    {{ claimTable?.bill_review_adjuster_name }}
                                  </td>
                                  <td style="width: 40%">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="{{ limosTable?.phone_1 }}"
                                      disabled
                                      formControlName="phone_1"
                                      id="phone_1"
                                      UsPhoneNoMask
                                      [formCtrl]="adjForm1.get('phone_1')"
                                    />
                                  </td>
                                  <td style="width: 40%">
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="{{ limosTable?.email_1 }}"
                                      disabled
                                      formControlName="email_1"
                                      id="email_1"
                                    />
                                  </td>
                                  <td style="width: 40%">
                                    <div class="d-flex">
                                      <button
                                        class="icon-button mr-2"
                                        id="enable-edit-adjuster-save1"
                                        (click)="
                                          enableElements(
                                            ['phone_1', 'email_1'],
                                            ['enable-edit-adjuster-save1']
                                          )
                                        "
                                      >
                                        <em class="fa fas fa-pencil"></em>
                                      </button>
                                      <button
                                        [disabled]="
                                          !emptyAdjForm1 || adjForm1.invalid
                                        "
                                        class="icon-button mr-2"
                                        (click)="adjusterSave1()"
                                      >
                                        <em class="fa fas fa-check"></em>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                          <form name="adjForm2" [formGroup]="adjForm2">
                            <table
                              class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl mb-0"
                            >
                              <caption>
                                Adjuster Table
                              </caption>
                              <thead>
                                <tr>
                                  <th class="border-0" scope="col">
                                    <b>DEFENSE ATTORNEY</b>
                                  </th>
                                  <th class="border-0" scope="col">PHONE:</th>
                                  <th class="border-0" scope="col">EMAIL:</th>
                                  <th class="border-0" scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bg-white">
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      value="{{ limosTable?.defense_attorney }}"
                                      formControlName="defense_attorney"
                                      id="defense_attorney"
                                    />
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="{{ limosTable?.phone_2 }}"
                                      disabled
                                      formControlName="phone_2"
                                      id="phone_2"
                                      UsPhoneNoMask
                                      [formCtrl]="adjForm2.get('phone_2')"
                                    />
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="{{ limosTable?.email_2 }}"
                                      disabled
                                      formControlName="email_2"
                                      id="email_2"
                                    />
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <div class="d-flex">
                                      <button
                                        class="icon-button mr-2"
                                        id="enable-edit-adjuster-save2"
                                        (click)="
                                          enableElements(
                                            [
                                              'defense_attorney',
                                              'phone_2',
                                              'email_2'
                                            ],
                                            ['enable-edit-adjuster-save2']
                                          )
                                        "
                                      >
                                        <em class="fa fas fa-pencil"></em>
                                      </button>
                                      <button
                                        [disabled]="
                                          !emptyAdjForm2 || adjForm2.invalid
                                        "
                                        class="icon-button mr-2"
                                        (click)="adjusterSave2()"
                                      >
                                        <em class="fa fas fa-check"></em>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                          <form name="adjForm3" [formGroup]="adjForm3">
                            <table
                              class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl mb-0"
                            >
                              <caption>
                                Adjuster Table
                              </caption>
                              <thead>
                                <tr>
                                  <th class="border-0" scope="col">
                                    ASSIGNED TO
                                  </th>
                                  <th class="border-0" scope="col">
                                    CASE MANAGER
                                  </th>
                                  <th class="border-0" scope="col">EMAIL</th>
                                  <th class="border-0" scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bg-white">
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <select
                                      name="assigned_to"
                                      formControlName="assigned_to"
                                      class="form-control"
                                      id="assigned_to"
                                      disabled
                                    >
                                      <option
                                        value=""
                                        [selected]="
                                          limosTable?.assigned_to === null ||
                                          limosTable?.assigned_to === ''
                                        "
                                      >
                                        Select Assigned To
                                      </option>
                                      <option
                                        value="{{ opt }}"
                                        [selected]="
                                          opt == limosTable?.assigned_to
                                        "
                                        *ngFor="let opt of assignedToUsers"
                                      >
                                        {{ opt }}
                                      </option>
                                    </select>
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <select
                                      name="assigned_to"
                                      formControlName="case_manager"
                                      class="form-control"
                                      id="case_manager"
                                      disabled
                                    >
                                      <option
                                        value=""
                                        [selected]="
                                          limosTable?.case_manager === null ||
                                          limosTable?.case_manager === ''
                                        "
                                      >
                                        Select Case Manager
                                      </option>
                                      <option
                                        value="{{ opt }}"
                                        [selected]="
                                          opt == limosTable?.case_manager
                                        "
                                        *ngFor="let opt of assignedToUsers"
                                      >
                                        {{ opt }}
                                      </option>
                                    </select>
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <input
                                      type="text"
                                      class="form-control"
                                      value="{{ limosTable?.email_3 }}"
                                      disabled
                                      formControlName="email_3"
                                      id="email_3"
                                    />
                                  </td>
                                  <td
                                    class="text-center align-middle"
                                    style="width: 40%"
                                  >
                                    <div class="d-flex">
                                      <button
                                        class="icon-button mr-2"
                                        id="enable-edit-adjuster-save3"
                                        (click)="
                                          enableElements(
                                            [
                                              'assigned_to',
                                              'case_manager',
                                              'email_3'
                                            ],
                                            ['enable-edit-adjuster-save3']
                                          )
                                        "
                                      >
                                        <em class="fa fas fa-pencil"></em>
                                      </button>
                                      <button
                                        [disabled]="
                                          !emptyAdjForm3 || adjForm3.invalid
                                        "
                                        class="icon-button mr-2"
                                        (click)="adjusterSave3()"
                                      >
                                        <em class="fa fas fa-check"></em>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="text-center text-dark-bold py-3">
                      LIEN CLAIMANTS
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="table-row">
                      <table
                        class="table providerTable limos-table-header-background small-text fixed-table table-row cnstr-record product-tbl"
                      >
                        <caption>
                          Data Table
                        </caption>
                        <thead>
                          <tr>
                            <th class="border-0" scope="col">PROVIDER NAME</th>
                            <th class="border-0" scope="col">
                              LIEN FILE STATUS
                            </th>

                            <th class="border-0" scope="col">TAX ID &#35;</th>
                            <th class="border-0" scope="col">TYPE OF LIEN</th>
                            <th class="border-0" scope="col">NPI &#35;</th>
                            <th class="border-0" scope="col">
                              PROVIDER REQUEST DATE
                            </th>
                            <th class="border-0" scope="col">
                              EAMS LIEN FILING
                            </th>
                            <th class="border-0" scope="col">LIEN AMOUNT</th>
                            <th class="border-0" scope="col">
                              PROVIDER REQUEST
                            </th>
                            <th class="border-0" scope="col">
                              AUTHORITY REQUESTED
                            </th>
                            <th class="border-0" scope="col">ASSIGNED</th>
                            <th class="border-0" scope="col">RESOLVED DATE</th>
                            <th class="border-0" scope="col">
                              RESOLVED AMOUNT
                            </th>
                            <th class="border-0" scope="col">SAVINGS</th>
                            <th class="border-0"></th>
                          </tr>
                        </thead>
                        <tbody *ngIf="claimantTable.length > 0">
                          <tr
                            class="bg-white"
                            *ngFor="let row of claimantTable"
                          >
                            <td class="position-relative">
                              <a
                                id="provider-{{ row.id }}"
                                href="/claimant-summary/{{ row.id }}/{{
                                  limosId
                                }}"
                                target="_blank"
                                class="primary-text font-weight-semibold"
                              >
                                {{ row.provider_name_business }}
                              </a>
                              <div
                                id="providerList-{{ row.id }}"
                                class="d-none"
                              >
                                <input
                                  id="providerListName-{{ row.id }}"
                                  type="text"
                                  class="form-control"
                                  value="{{ row.provider_name_business }}"
                                  [ngbTypeahead]="search"
                                  [resultTemplate]="rt"
                                  [inputFormatter]="formatter"
                                />
                                <div *ngIf="preloader" class="preloader"></div>
                                <ng-template #rt let-r="result" let-t="term">
                                  <div [term]="t" (click)="selectedProvider(r)">
                                    {{ r.provider_name_business }}
                                    &nbsp;&nbsp;&nbsp;
                                    {{ "TIN " + r.tin }} &nbsp;&nbsp;&nbsp;
                                    {{ "NPI " + r.npi }}
                                  </div>
                                </ng-template>
                              </div>
                            </td>
                            <td>
                              <input
                                id="tax-{{ row.id }}"
                                disabled="disabled"
                                class="form-control"
                                value="{{ row.status }}"
                              />
                            </td>
                            <td>
                              <input
                                id="tax-{{ row.id }}"
                                disabled="disabled"
                                class="form-control"
                                value="{{ row.tax_id_number }}"
                              />
                            </td>
                            <td>
                              <select
                                id="type-{{ row.id }}"
                                disabled="disabled"
                                class="form-control"
                              >
                                <option
                                  value=""
                                  [selected]="
                                    row.lien_type === null ||
                                    row.lien_type === ''
                                  "
                                ></option>
                                <option
                                  value="{{ opt }}"
                                  [selected]="opt == row.lien_type"
                                  *ngFor="let opt of lienType"
                                >
                                  {{ opt }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <input
                                id="npi-{{ row.id }}"
                                disabled="disabled"
                                type="number"
                                class="form-control"
                                value="{{ row.provider_npi_number }}"
                              />
                            </td>
                            <td>
                              <input
                                id="reqdate-{{ row.id }}"
                                disabled="disabled"
                                type="text"
                                class="form-control"
                                [value]="
                                  row.lien_request_date
                                    | date : clientDateFormat
                                "
                                jDate
                              />
                            </td>
                            <td>
                              <input
                                id="createddate-{{ row.id }}"
                                type="text"
                                disabled="disabled"
                                class="form-control"
                                [value]="
                                  row.lien_created | date : clientDateFormat
                                "
                                jDate
                              />
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <span *ngIf="row.lien_amount">&#36;</span
                                ><input
                                  id="lienamount-{{ row.id }}"
                                  type="number"
                                  disabled="disabled"
                                  class="form-control"
                                  value="{{
                                    row.lien_amount > 0
                                      ? (row.lien_amount | decimalPoint)
                                      : null
                                  }}"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <span *ngIf="row.lien_requested_amount"
                                  >&#36;</span
                                ><input
                                  id="reqamount-{{ row.id }}"
                                  type="number"
                                  disabled="disabled"
                                  class="form-control"
                                  value="{{
                                    row.lien_requested_amount > 0
                                      ? (row.lien_requested_amount
                                        | decimalPoint)
                                      : null
                                  }}"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <span *ngIf="row.authority_requested"
                                  >&#36;</span
                                ><input
                                  id="authorityRequested-{{ row.id }}"
                                  type="number"
                                  disabled="disabled"
                                  class="form-control"
                                  value="{{
                                    row.authority_requested > 0
                                      ? (row.authority_requested | decimalPoint)
                                      : null
                                  }}"
                                />
                              </div>
                            </td>

                            <td>
                              <select
                                id="assignedType-{{ row.id }}"
                                disabled="disabled"
                                class="form-control"
                              >
                                <option
                                  value=""
                                  [selected]="
                                    row.assigned === null || row.assigned === ''
                                  "
                                ></option>
                                <option
                                  value="{{ opt }}"
                                  [selected]="opt == row.assigned"
                                  *ngFor="let opt of assignedType"
                                >
                                  {{ opt }}
                                </option>
                              </select>
                            </td>

                            <td>
                              {{
                                row.lien_resolved_date | date : clientDateFormat
                              }}
                            </td>
                            <td>
                              {{
                                row.lien_resolved_amount
                                  | decimalPoint
                                  | dollarCurrency
                              }}
                            </td>
                            <td>
                              {{ row.saving
                              }}<span *ngIf="row.saving">&#37;</span>
                            </td>
                            <td>
                              <div class="d-flex">
                                <button
                                  id="edit-{{ row.id }}"
                                  class="icon-button edit mr-2"
                                  (click)="makeEditable(row.id)"
                                >
                                  <em class="fa fas fa-pencil"></em>
                                </button>
                                <button
                                  id="save-{{ row.id }}"
                                  class="icon-button mr-2"
                                  (click)="
                                    saveEditable(row.id, typeAheadSelectInfo)
                                  "
                                  hidden
                                >
                                  <em class="fa fas fa-check"></em>
                                </button>
                                <button
                                  id="cancel-{{ row.id }}"
                                  class="icon-button"
                                  (click)="cancelEditable(row.id)"
                                  hidden
                                >
                                  <em class="fa fas fa-close"></em>
                                </button>
                                <button
                                  id="delete-{{ row.id }}"
                                  class="icon-button"
                                  (click)="
                                    openDeleteClaimantModal(
                                      deleClaimantEntry,
                                      row.id
                                    )
                                  "
                                >
                                  <em
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                  ></em>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr class="bg-white">
                            <td class="position-relative">
                              <div>
                                <input
                                  id="provider_name"
                                  type="text"
                                  class="form-control"
                                  [(ngModel)]="model2"
                                  [ngbTypeahead]="search2"
                                  [resultTemplate]="rt2"
                                  [inputFormatter]="formatter"
                                />
                                <div *ngIf="preloader2" class="preloader"></div>
                                <ng-template #rt2 let-r="result" let-t="term">
                                  <div
                                    [term]="t"
                                    (click)="selectedProviderAdd(r)"
                                  >
                                    {{ r.provider_name_business }}
                                    &nbsp;&nbsp;&nbsp;
                                    {{ "TIN " + r.tin }} &nbsp;&nbsp;&nbsp;
                                    {{ "NPI " + r.npi }}
                                  </div>
                                </ng-template>
                              </div>
                            </td>
                            <td>
                              <input class="form-control" id="tax_add" />
                            </td>
                            <td>
                              <select
                                id="type_add"
                                class="form-control lient_select"
                                [(ngModel)]="selectedLienType"
                              >
                                <option value="Select a lien type">
                                  Select a lien type
                                </option>
                                <option
                                  value="{{ opt }}"
                                  *ngFor="let opt of lienType"
                                >
                                  {{ opt }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                id="npi_add"
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                id="reqdate_add"
                                jDate
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                id="createddate_add"
                                jDate
                              />
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <span>&#36;</span
                                ><input
                                  type="number"
                                  class="form-control"
                                  id="lien_amount_add"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <span>&#36;</span
                                ><input
                                  type="number"
                                  class="form-control"
                                  id="reqamount_add"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <span>&#36;</span
                                ><input
                                  type="number"
                                  class="form-control"
                                  id="authorityRequested_add"
                                />
                              </div>
                            </td>
                            <td>
                              <select
                                id="assigned_type_add"
                                class="form-control lient_select"
                                [(ngModel)]="selectedAssignedType"
                              >
                                <option value="Select a assigned type">
                                  Select a assigned type
                                </option>
                                <option
                                  value="{{ opt }}"
                                  *ngFor="let opt of assignedType"
                                >
                                  {{ opt }}
                                </option>
                              </select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>
                              <div class="d-flex">
                                <button
                                  class="icon-button"
                                  (click)="addLien(typeAheadSelectInfo)"
                                >
                                  <em class="fa fas fa-plus"></em>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab == 'Time Management'">
      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <time-management
              (teamSelected)="handleteamManagementSelected($event)"
              [claimantTable]="claimantTable"
              [claimTable]="claimTable"
            ></time-management>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab == 'Invoicing'">
      <div class="col-md-12" *blockUI="'invoice-list-ui'">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div class="row">
              <div class="col-12">
                <div class="text-center text-dark-bold py-3 position-relative">
                  INVOICES
                </div>
              </div>
              <div class="col-12" *ngIf="showTimePostForm">
                <div class="time-post-form">
                  <button class="btn-close" (click)="hideTimePostForm()">
                    <em class="fa fas fa-close"></em>
                  </button>
                  <form name="form" [formGroup]="form">
                    <div class="row">
                      <div class="col-6">
                        <div class="row mb-1">
                          <div class="col-4">Provider</div>
                          <div class="col-8">
                            <select
                              name="provider_name"
                              id="provider_name"
                              formControlName="provider"
                              class="form-control"
                            >
                              <option value="" disabled>Select</option>
                              <option
                                [value]="claimant.provider_name_business"
                                *ngFor="let claimant of claimantTable"
                              >
                                {{ claimant.provider_name_business }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-4">Time Type</div>
                          <div class="col-8">
                            <select
                              class="form-control"
                              formControlName="time_type"
                            >
                              <option value="Initial Lien Review">
                                Initial Lien Review
                              </option>
                              <option value="Lien Negotiation">
                                Lien Negotiation
                              </option>
                              <option value="Client Correspondence">
                                Client Correspondence
                              </option>
                              <option value="D/A Correspondence">
                                D/A Correspondence
                              </option>
                              <option value="SIU Investigation">
                                SIU Investigation
                              </option>
                              <option value="SIU Clerical">SIU Clerical</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-4">Time Spent</div>
                          <div class="col-8">
                            <input
                              type="number"
                              formControlName="time_spent"
                              class="form-control"
                              placeholder="Enter Time Spent"
                            />
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-4">Date</div>
                          <div class="col-8">
                            <input
                              type="text"
                              formControlName="date"
                              class="form-control"
                              [formCtrl]="form.get('date')"
                              jDate
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-2">Note</div>
                          <div class="col-10">
                            <textarea
                              type="text"
                              formControlName="note"
                              class="form-control"
                              placeholder="Enter Note"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="app-limos">
              <div class="row">
                <div class="col-12">
                  <div class="invoice-listing">
                    <app-invoice-list
                      [invoiceLimit]="invoiceLimit"
                      (invoiceSelected)="handleInvoiceSelected($event)"
                      [selectedInvoice]="selectedInvoice"
                      (invoiceData)="handleInvoiceData($event)"
                    >
                    </app-invoice-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <time-management
              [activeTab]="activeTab"
              (teamSelected)="handleteamManagementSelected($event)"
              [invoices]="invoices"
              [claimantTable]="claimantTable"
              [claimTable]="claimTable"
              [isInvoice]="true"
            >
            </time-management>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab == 'Notes'">
      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div class="row">
              <div class="col-12">
                <div
                  class="mb-1 font-weight-bold text-dark py-2 d-flex align-items-center justify-content-between px-2"
                >
                  <div class="w-100 text-center">Notepad</div>

                  <button
                    class="btn btn-sm limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2 text-nowrap"
                    (click)="openPopup(createNote, 'lg')"
                    type="button"
                  >
                    Add Note
                  </button>
                </div>

                <div class="scrolling-box mb-1">
                  <div class="table" *blockUI="'notes-list-ui'">
                    <table
                      class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                    >
                      <!-- <tbody *ngIf="notes?.length">
                        <tr class="bg-white" *ngFor="let note of notes">
                          <td>{{ note.created_at | date : "MM/dd/yyyy" }}</td>
                          <td>{{ note.title }}</td>
                          <td>{{ note.access_group }}</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              (click)="downloadNoteFile(note?.file)"
                              class="text-danger text-sm btn-link"
                              >{{ note?.file?.file_name }}</a
                            >
                            <i
                              *ngIf="
                                note?.file &&
                                note?.file?.file_name &&
                                isDownloadStarted[note?.file?.id]
                              "
                              class="ml-1 fa fa-spinner fa-spin"
                            ></i>
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-primary"
                              title="Edit"
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              class="btn btn-sm text-danger"
                              title="Remove"
                              (click)="deleteNote(note.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody> -->

                      <tbody *ngIf="notes.length > 0">
                        <tr class="bg-white" *ngFor="let note of notes">
                          <div class="card m-2">
                            <div
                              class="card-header text-sm d-flex justify-content-between"
                            >
                              <div>
                                {{ note.created_at | date : clientDateFormat }}
                              </div>
                              <div>
                                <a href="javascript:void(0)">Invalidate</a>
                                <a href="javascript:void(0)" class="ml-2"
                                  >Send</a
                                >
                                <a href="javascript:void(0)" class="ml-2"
                                  >Reply</a
                                >
                                <a href="javascript:void(0)" class="ml-2"
                                  >Duplicate</a
                                >
                                <a
                                  href="javascript:void(0)"
                                  class="ml-2"
                                  (click)="editNote(createNote, note)"
                                  >Edit</a
                                >
                                <a
                                  href="javascript:void(0)"
                                  class="ml-2"
                                  (click)="deleteNote(note.id)"
                                  >Delete</a
                                >
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-9 text-sm">
                                  <span class="text-dark-bold"> Title:</span>
                                  <div>
                                    {{ note.title }}
                                  </div>
                                </div>
                                <div
                                  class="col-3 text-sm"
                                  *ngIf="note?.file?.file_name"
                                >
                                  <div
                                    class="d-flex justify-content-center align-items-center flex-column w-50"
                                  >
                                    <div class="text-black-contrast">
                                      Attachment
                                    </div>
                                    <a
                                      alt=""
                                      href="javascript:void(0)"
                                      (click)="downloadNoteFile(note?.file)"
                                      [title]="note?.file?.file_name"
                                    >
                                      <ng-template #showFile>
                                        <span class="file-icon"></span>
                                      </ng-template>

                                      <i
                                        *ngIf="
                                          note?.file &&
                                            note?.file?.file_name &&
                                            isDownloadStarted[note?.file?.id];
                                          else showFile
                                        "
                                        class="ml-1 fa fa-spinner fa-spin"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="row my-1">
                                <div class="col-9 text-sm">
                                  <span class="text-dark-bold">
                                    Description:</span
                                  >
                                  <div [innerHTML]="note.body"></div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-9 text-sm">
                                  <div>
                                    <span class="text-gray">
                                      <i class="fa fa-file"></i>
                                      Posted By:
                                      <i
                                        >{{ note?.user?.first_name }}
                                        {{ note?.user?.last_name }} on
                                        {{
                                          note?.created_at | clientDateFormat
                                        }}
                                      </i>
                                    </span>
                                  </div>
                                  <div>
                                    <span class="text-gray">
                                      <i class="fa fa-key"></i>
                                      Access Group:
                                      <i>{{ note.access_group }}</i>
                                    </span>
                                  </div>

                                  <div>
                                    <span class="text-gray">
                                      <i class="fa fa-check"></i>
                                      Validated By:
                                      <i
                                        >{{ note?.user?.first_name }}
                                        {{ note?.user?.last_name }} on
                                        {{
                                          note?.created_at | clientDateFormat
                                        }}</i
                                      >
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </tr>
                      </tbody>

                      <ng-template *ngIf="!notes?.length">
                        <tbody>
                          <tr class="bg-white">
                            <td colspan="5" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </tbody>
                      </ng-template>
                    </table>
                    <div
                      class="px-0 py-0 paginationWrapper limosPaginationWrapper d-flex"
                    >
                      <ngb-pagination
                        [(collectionSize)]="noteTotal"
                        [(page)]="notePage"
                        [pageSize]="noteLimit"
                        [maxSize]="10"
                        [boundaryLinks]="true"
                        (pageChange)="onPageChangeNotes($event)"
                      >
                      </ngb-pagination>

                      <div class="selected d-flex align-items-center">
                        <select
                          (change)="onSizeChangeNotes($event.target.value)"
                          class="custom-select table-select h-100"
                          [(ngModel)]="noteLimit"
                        >
                          <option [value]="30">30</option>
                          <option [value]="50">50</option>
                          <option [value]="75">75</option>
                          <option [value]="100">100</option>
                        </select>
                        <p class="mb-0 ml-2 text-nowrap">
                          Showing {{ notePage * noteLimit - noteLimit + 1 }} -
                          {{
                            notePage * noteLimit > noteTotal
                              ? noteTotal
                              : notePage * noteLimit
                          }}
                          of {{ noteTotal }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab == 'Resources'">
      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body search-limos-background"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab == 'Diary'">
      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div class="row">
              <div class="col-12">
                <div
                  class="mb-1 font-weight-bold text-dark py-2 d-flex align-items-center justify-content-between px-2"
                >
                  <div class="text-center w-100">Diary</div>

                  <button
                    class="btn btn-sm limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2 text-nowrap"
                    (click)="openPopup(createDiary, 'lg')"
                    type="button"
                  >
                    Add Diary
                  </button>
                </div>

                <div class="scrolling-box mb-1">
                  <div class="table" *blockUI="'diaries-list-ui'">
                    <table
                      class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                      *ngIf="claimantTable"
                    >
                      <caption>
                        Data Table
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Case #</th>
                          <th class="border-0" scope="col">Case Name</th>
                          <th class="border-0" scope="col">Type</th>
                          <th class="border-0" scope="col">Due Date</th>
                          <th class="border-0">Action</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="diaries?.length">
                        <tr class="bg-white" *ngFor="let diary of diaries">
                          <td>{{ diary.case_number }}</td>
                          <td>{{ diary.case_name }}</td>
                          <td>{{ diary.type }}</td>
                          <td>
                            {{ diary.due_date | date : clientDateFormat }}
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-primary"
                              title="Edit"
                              (click)="editDiary(createDiary, diary)"
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              (click)="deleteDiary(diary.id)"
                              class="btn btn-sm text-danger"
                              title="Remove"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <ng-template *ngIf="!diaries?.length">
                        <tbody>
                          <tr class="bg-white">
                            <td colspan="5" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </tbody>
                      </ng-template>
                    </table>
                    <div
                      class="px-0 py-0 paginationWrapper limosPaginationWrapper d-flex"
                    >
                      <ngb-pagination
                        [(collectionSize)]="diaryTotal"
                        [(page)]="diaryPage"
                        [pageSize]="diaryLimit"
                        [maxSize]="10"
                        [boundaryLinks]="true"
                        (pageChange)="onPageChangeDiaries($event)"
                      >
                      </ngb-pagination>

                      <div class="selected d-flex align-items-center">
                        <select
                          (change)="onSizeChangeDiaries($event.target.value)"
                          class="custom-select table-select h-100"
                          [(ngModel)]="diaryLimit"
                        >
                          <option [value]="30">30</option>
                          <option [value]="50">50</option>
                          <option [value]="75">75</option>
                          <option [value]="100">100</option>
                        </select>
                        <p class="mb-0 ml-2 text-nowrap">
                          Showing
                          {{ diaryPage * diaryLimit - diaryLimit + 1 }} -
                          {{
                            diaryPage * diaryLimit > diaryTotal
                              ? diaryTotal
                              : diaryPage * diaryLimit
                          }}
                          of {{ diaryTotal }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- Modal -->
<ng-template #deleClaimantEntry let-modal>
  <div *blockUI="'show-loader'">
    <div class="modal-header">
      <h4 class="modal-title">Confirm Deletion</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="flex text-center flex-col w-full">
        <p>Are you sure you want to delete the Provider entry?</p>
        <div class="flex">
          <button
            class="btn primary-bg text-white btnlogin mr-3"
            (click)="deleteClaimant()"
          >
            Yes
          </button>
          <button
            class="btn bg-orange text-white btnlogin"
            (click)="modal.dismiss('Cross click')"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleTimeEntry let-modal>
  <div *blockUI="'show-loader'">
    <div class="modal-header">
      <h4 class="modal-title">Confirm Deletion</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="flex text-center flex-col w-full">
        <p>Are you sure you want to delete this entry?</p>
        <div class="flex">
          <button
            class="btn primary-bg text-white btnlogin mr-3"
            (click)="deleteTime()"
          >
            Yes
          </button>
          <button
            class="btn bg-orange text-white btnlogin"
            (click)="modal.dismiss('Cross click')"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #limosStatusModal let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>
        There are unresolved Providers listed below, the status cannot be
        updated.
      </p>
      <div class="flex">
        <button
          class="btn bg-voilet limos-button-background text-white"
          (click)="modal.dismiss('Cross click')"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #limosStatusModalSuccess let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>LIMOS &#35;{{ claimTable.id }} is now Closed.</p>
      <div class="flex">
        <button
          class="btn bg-voilet limos-button-background text-white"
          (click)="modal.dismiss('Cross click')"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #typeAheadSelectInfo let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>Please select provider from the search result list</p>
      <div class="flex">
        <button
          class="btn bg-voilet limos-button-background text-white"
          (click)="confirmTypeaheadSelect()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createDiary let-modal>
  <div class="modal-header">
    <h6>{{ editDiaryData?.id ? "Update" : "Add" }} Diary</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addModal.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-diary text-sm">
    <form [formGroup]="diaryForm">
      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="access_group"
              >Case Number</span
            >
            <input
              type="text"
              class="form-control text-sm"
              name="case_number"
              id="case_number"
              [value]="claimTable?.id"
              formControlName="case_number"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="case_name">Case Name</span>
            <input
              type="text"
              name="case_name"
              id="case_name"
              class="form-control"
              [value]="claimTable?.claimant_name"
              formControlName="case_name"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_type">Type</span>
            <input
              type="text"
              class="form-control"
              name="case_type"
              id="case_type"
              formControlName="type"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="due_date">Due Date</span>
            <input
              type="text"
              name="due_date"
              id="due_date"
              class="form-control"
              [value]="diaryForm.get('due_date')?.value | clientDateFormat"
              jDate
              [formCtrl]="diaryForm.get('due_date')"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 mr-1" for="caseNote">Note</span>
            <textarea
              class="text-black"
              name="caseNote"
              rows="20"
              id="caseNote"
              class="form-control"
              formControlName="note"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">
          <span class="text-dark fw-500 mr-1" for="case_assigned_to"
            >Assigned To</span
          >
          <select
            name="case_assigned_to"
            class="form-control"
            id="case_assigned_to"
            formControlName="assigned_to"
          >
            <option value="">Select</option>
            <option value="{{ opt }}" *ngFor="let opt of assignedToUsers">
              {{ opt }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="addModal.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="onSubmitDiary()"
            [disabled]="diaryForm.invalid"
          >
            {{ editDiaryData?.id ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #createNote let-modal>
  <div class="modal-header">
    <h6>{{ editNoteData?.id ? "Edit" : "Add" }} Note</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addModal.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-note text-sm">
    <form [formGroup]="noteForm">
      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="access_group"
              >Access Group</span
            ><span class="text-danger text-sm">*</span>
            <select
              name="access_group"
              id="access_group"
              class="form-control"
              formControlName="access_group"
            >
              <option value="">Select</option>
              <option [value]="opt" *ngFor="let opt of accessGroups">
                {{ opt }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500" for="subject">Subject</span>
            <input
              type="text"
              name="subject"
              id="subject"
              class="form-control"
              formControlName="subject"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 mr-1" for="noteTitle">Title</span
            ><span class="text-danger text-sm">*</span>
            <input
              type="text"
              name="noteTitle"
              id="noteTitle"
              class="form-control"
              formControlName="title"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 mr-1" for="noteBody">Body</span
            ><span class="text-danger text-sm">*</span>
            <ckeditor
              id="noteBody"
              [editor]="editor"
              [(ngModel)]="noteBodyeditor"
              [data]="noteBodyeditor"
              formControlName="body"
            ></ckeditor>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="noteTags">Tags</span>
            <span class="text-sm">(Press enter to add tags.)</span>
            <div class="border p-1 rounded">
              <ng-container *ngFor="let tag of selectedNoteTags; let i = index">
                <span class="bg-light-dark text-dark text-sm mr-1 p-1 rounded">
                  {{ tag }}
                  <em
                    title="Remove"
                    class="fa fa-remove text-danger cursor-pointer"
                    (click)="selectedNoteTags.splice(i, 1)"
                  ></em>
                </span>
              </ng-container>
              <input
                type="text"
                name="noteTags"
                id="noteTags"
                class="border-0 text-sm"
                placeholder="Enter tags here"
                (keydown.enter)="addNoteTags($event)"
              />
            </div>
            <span class="text-sm"
              >A tag is a keyword or label that categorizes your update with
              other, similar updates.</span
            >
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <div>
            <div class="text-dark fw-500 mb-1" for="noteFiles">
              File
              <span class="text-sm mr-1 text-gray">(Max File Size: 5G)</span>
              <button
                type="button"
                class="btn btn-sm btn-primary rounded"
                (click)="openNotefilesInputDialog(notefilesInput)"
                title="Add Files"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <input
              type="file"
              #notefilesInput
              class="d-none"
              name="noteFiles"
              id="noteFiles"
              accept=".pdf,.xls,.xlsx,.png,.jpg,.jpeg,.doc,.docx"
              (change)="onNoteFileSelected($event)"
            />
          </div>
          <div *ngIf="selectedNoteFiles?.length; else noFilesSelected">
            <ol class="px-3">
              <li
                *ngFor="let file of selectedNoteFiles; let i = index"
                class="text-black text-sm"
              >
                <span class="text-dark-bold">{{ file.name }}</span>
                <button
                  class="btn btn-sm text-danger"
                  title="Remove"
                  (click)="selectedNoteFiles.splice(i, 1)"
                >
                  <em class="fa fa-remove"></em>
                </button>
              </li>
            </ol>
          </div>
          <ng-template #noFilesSelected>
            <span class="text-sm">No Files selected.</span>
          </ng-template>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-between">
        <div>
          <ng-container *ngIf="!editNoteData?.id">
            <input
              type="checkbox"
              name="expense_entry"
              id="expense_entry"
              [(ngModel)]="createExpenseEntry"
              formControlName="create_expense_entry"
            /><label for="expense_entry" class="text-dark fw-500 ml-1"
              >Create expense entry after save</label
            >
          </ng-container>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="addModal.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="submitNotepad(createTimeSpent)"
            [disabled]="noteForm.invalid"
          >
            {{ editNoteData?.id ? "update" : "Save" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #createTimeSpent let-modal>
  <div class="modal-header">
    <h6>Add Time Spent</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addModal.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-time-spent">
    <div class="row mb-2">
      <div class="col-12">
        <div>
          <input
            type="text"
            name="time_spent"
            id="time_spent"
            class="form-control text-center"
            mask="00.00"
            placeholder="hh.mm"
            [(ngModel)]="noteTimeSpent"
            [dropSpecialCharacters]="false"
          />
        </div>
      </div>
    </div>

    <div class="mt-4 text-right">
      <div>
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="addModal.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          [disabled]="noteTimeSpent ? !noteTimeSpent.split('.')[1] : true"
          (click)="createExpense()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>
