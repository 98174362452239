import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangePasswordModel } from '../models/account/change.password.model';
import { CurrentUserProfile } from '../models/account/current.user.profile';
import { ForgotPasswordModel } from '../models/account/forgot.password.model';
import { ResetPasswordModel } from '../models/account/reset.password.model';
import { LoginModel } from '../models/LoginModel';

@Injectable()
export class AccountService {
  private userInfoData: BehaviorSubject<CurrentUserProfile> =
    new BehaviorSubject<CurrentUserProfile>(null);

  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: typedef
  login(model: LoginModel) {
    return this.http.post(
      environment.fireStoreApiUrl + '/api/v1/auth/token/',
      model
    );
  }
  // tslint:disable-next-line: typedef
  getCurrentUserProfile() {
    //return this.http.get(environment.apiUrl + 'account/get-current-user-profile');
    return this.http
      .get(environment.fireStoreApiUrl + '/api/v1/user/info/')
      .pipe(
        tap((userInfo: CurrentUserProfile) => {
          this.userInfoData.next(userInfo);
        })
      );
  }

  changePassword(model: ChangePasswordModel) {
    return this.http.post(
      environment.apiUrl + 'account/change-password',
      model
    );
  }

  SendResetPasswordLink(model: ForgotPasswordModel) {
    return this.http.post(
      environment.apiUrl + 'account/send-reset-password-link',
      model
    );
  }

  resetPassword(model: ResetPasswordModel) {
    return this.http.post(environment.apiUrl + 'account/reset-password', model);
  }

  set userInfo(value: CurrentUserProfile) {
    this.userInfoData.next(value);
  }

  get userInfo(): CurrentUserProfile {
    return this.userInfoData.getValue();
  }

  $userInfo = this.userInfoData.asObservable();
}
