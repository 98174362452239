import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  settings = [
    {
      row: 1,
      columns: [
        {
          name: 'Users and Roles',
          className: 'col-4',
          links: [
            {
              name: 'Access Groups',
              link: '/',
            },
            {
              name: 'User Roles',
              link: '/',
            },
            {
              name: 'Security Settings',
              link: '/',
            },
          ],
        },
        {
          name: 'Dashboard',
          className: 'col-4',
          links: [],
        },
        {
          name: 'Departments',
          className: 'col-4',
          links: [
            {
              name: 'Department Names',
              link: '/settings/department-names',
            },
            {
              name: 'Department Columns',
              link: '/settings/department-columns',
            },
          ],
        },
      ],
    },
    {
      row: 2,
      columns: [
        {
          name: 'Email Settings',
          className: 'col-4',
          links: [],
        },
        {
          name: 'Document Templates',
          className: 'col-4',
          links: [],
        },
        {
          name: 'Case Settings',
          className: 'col-4',
          links: [],
        },
      ],
    },
    {
      row: 3,
      columns: [
        {
          name: 'Diaries',
          className: 'col-4',
          links: [],
        },
        {
          name: 'Invoicing',
          className: 'col-4',
          links: [],
        },
      ],
    },
  ];
  ngOnInit(): void {}
}
