<div class="row">
  <div class="col-12">
    <div class="text-center text-dark-bold py-3 position-relative">
      TIME MANAGEMENT
      <div class="time-management-buttons">
        <button
          class="btn small btn-info btn-sm text-white px-2 py-1 pr-2 pl-2 mr-2"
          *ngIf="activeTab === 'Invoicing' && showInvoiceBtn()"
          (click)="convertToInvoice()"
        >
          Convert Into Invoice
        </button>
        <button
          [disabled]="showTimePostForm"
          (click)="visTimePostForm()"
          class="btn small btn-sm bg-voilet limos-button-background text-white px-2 py-1 pr-2 pl-2 mr-2"
        >
          <em class="fa fas fa-plus mr-3"></em>Add
        </button>
        <button
          [disabled]="!emptyForm || form.invalid"
          (click)="saveTimePostForm()"
          class="btn small btn-sm bg-voilet limos-button-background text-white px-2 py-1 pr-2 pl-2"
        >
          <em class="fa fas fa-save mr-3"></em>Save
        </button>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="showTimePostForm">
    <div class="time-post-form">
      <button class="btn-close" (click)="hideTimePostForm()">
        <em class="fa fas fa-close"></em>
      </button>
      <form name="form" [formGroup]="form">
        <div class="row">
          <div class="col-6">
            <div class="row mb-1">
              <div class="col-4">Provider</div>
              <div class="col-8">
                <select
                  name="provider_name"
                  id="provider_name"
                  formControlName="provider"
                  class="form-control"
                >
                  <option value="" disabled>Select</option>
                  <option
                    [value]="claimant.provider_name_business"
                    *ngFor="let claimant of claimantTable"
                  >
                    {{ claimant.provider_name_business }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-4">Time Type</div>
              <div class="col-8">
                <select class="form-control" formControlName="time_type">
                  <option value="Initial Lien Review">
                    Initial Lien Review
                  </option>
                  <option value="Lien Negotiation">Lien Negotiation</option>
                  <option value="Client Correspondence">
                    Client Correspondence
                  </option>
                  <option value="D/A Correspondence">D/A Correspondence</option>
                  <option value="SIU Investigation">SIU Investigation</option>
                  <option value="SIU Clerical">SIU Clerical</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-4">Time Spent</div>
              <div class="col-8">
                <input
                  type="number"
                  formControlName="time_spent"
                  class="form-control"
                  placeholder="Enter Time Spent"
                />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-4">Date</div>
              <div class="col-8">
                <input
                  type="text"
                  formControlName="date"
                  class="form-control"
                  [formCtrl]="form.get('date')"
                  jDate
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-2">Note</div>
              <div class="col-10">
                <textarea
                  type="text"
                  formControlName="note"
                  class="form-control"
                  placeholder="Enter Note"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="table-row">
      <table
        class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl mb-0"
        *ngIf="timeSpentLogs"
      >
        <caption>
          Data Table
        </caption>
        <thead>
          <tr>
            <th class="border-0" scope="col" *ngIf="activeTab === 'Invoicing'">
              <input
                type="checkbox"
                name=""
                id="check-all-time-mgmt"
                [checked]="isCheckAllTimeMgmt()"
                (change)="checkAllTimeMgmt($event)"
              />
            </th>
            <th class="border-0" scope="col">DATE</th>
            <th class="border-0" scope="col">PROVIDER NAME</th>
            <th class="border-0" scope="col">TIME TYPE</th>
            <th class="border-0" scope="col">TIME NOTE</th>
            <th class="border-0" scope="col">TIME SPENT</th>
            <th class="border-0" scope="col">RATE</th>
            <th class="border-0" scope="col">BILLABLE RATE</th>

            <th class="border-0" scope="col">USER</th>
            <th class="border-0" scope="col"></th>
          </tr>
        </thead>
        <tbody *blockUI="'team-list'">
          <tr class="bg-white" *ngFor="let row of timeSpentLogs">
            <td *ngIf="activeTab === 'Invoicing'">
              <input
                type="checkbox"
                name=""
                id="check-time-mgmt-{{ row.id }}"
                [(ngModel)]="row.checked"
              />
            </td>
            <td>
              <input
                id="log_date-{{ row.id }}"
                disabled="disabled"
                class="form-control"
                type="text"
                [value]="row.log_date | date : clientDateFormat"
                jDate
              />
            </td>
            <td class="position-relative">
              <select
                disabled="disabled"
                class="form-control"
                id="provider_name-{{ row.id }}"
              >
                <option value="" disabled>Select</option>
                <option
                  [value]="claimant.provider_name_business"
                  *ngFor="let claimant of claimantTable"
                  [selected]="
                    row.provider_name_business ==
                    claimant.provider_name_business
                  "
                >
                  {{ claimant.provider_name_business }}
                </option>
              </select>
            </td>
            <td>
              <input
                id="time_type-{{ row.id }}"
                disabled="disabled"
                class="form-control"
                value="{{ row.time_type }}"
              />
            </td>
            <td>
              <input
                id="note-{{ row.id }}"
                disabled="disabled"
                class="form-control"
                value="{{ row.note }}"
              />
            </td>
            <td>
              <input
                id="time_spent-{{ row.id }}"
                disabled="disabled"
                class="form-control"
                value="{{ row.time_spent }}"
              />
            </td>
            <td class="d-flex">
              {{ row.rate | currency }}
            </td>
            <td>
              <input
                id="time_spent-{{ row.id }}"
                disabled="disabled"
                class="form-control"
                value="{{ row.billable_rate }}"
              />
            </td>
            <td>{{ row.user }}</td>
            <td>
              <button
                id="editTime-{{ row.id }}"
                class="icon-button mr-2 editTime"
                (click)="makeEditableTime(row.id)"
              >
                <em class="fa fas fa-pencil"></em>
              </button>
              <button
                id="saveTime-{{ row.id }}"
                class="icon-button mr-2"
                (click)="saveEditableTime(row.id)"
                hidden
              >
                <em class="fa fas fa-check"></em>
              </button>
              <button
                id="cancelTime-{{ row.id }}"
                class="icon-button mr-2"
                (click)="cancelEditableTime(row.id)"
                hidden
              >
                <em class="fa fas fa-close"></em>
              </button>
              <button
                id="deleteTime-{{ row.id }}"
                class="icon-button deleteTime"
                (click)="openDeleteTimeEntryModal(deleTimeEntry, row.id)"
              >
                <em class="fa fa-trash" aria-hidden="true"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="px-0 py-2 paginationWrapper spearPaginationWrapper d-flex"
        *ngIf="timeSpentLogs?.length"
      >
        <ngb-pagination
          [(collectionSize)]="timeLogTotal"
          [(page)]="timeLogPage"
          [pageSize]="timeLogLimit"
          [maxSize]="10"
          [boundaryLinks]="true"
          (pageChange)="onPageChangeTimeLog($event)"
        >
        </ngb-pagination>

        <div class="selected d-flex align-items-center">
          <select
            (change)="onSizeChangeTimeLog($event.target.value)"
            class="custom-select table-select h-100"
            [(ngModel)]="timeLogLimit"
          >
            <option [value]="30">30</option>
            <option [value]="50">50</option>
            <option [value]="75">75</option>
            <option [value]="100">100</option>
          </select>
          <p class="mb-0 ml-2 text-nowrap">
            Showing
            {{ timeLogPage * timeLogLimit - timeLogLimit + 1 }} -
            {{
              timeLogPage * timeLogLimit > timeLogTotal
                ? timeLogTotal
                : timeLogPage * timeLogLimit
            }}
            of {{ timeLogTotal }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleTimeEntry let-modal>
  <div *blockUI="'manage-users-blockUI'">
    <div class="modal-header">
      <h4 class="modal-title">Confirm Deletion</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="flex text-center flex-col w-full">
        <p>Are you sure you want to delete this entry?</p>
        <div class="flex">
          <button
            class="btn primary-bg text-white btnlogin mr-3"
            (click)="deleteTime()"
          >
            Yes
          </button>
          <button
            class="btn bg-orange text-white btnlogin"
            (click)="modal.dismiss('Cross click')"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
