<div class="invoices-list bg-white" *blockUI="'invoice-list'">
  <table class="table spear-table-header-background search-spear-background">
    <caption>
      NPI
    </caption>
    <thead>
      <tr>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('invoice_number')"
        >
          Invoice No.
          <app-table-column-sort
            [columnName]="'invoice_number'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('limos_case_number')"
        >
          Case Number
          <app-table-column-sort
            [columnName]="'limos_case_number'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('status')"
        >
          Status
          <app-table-column-sort
            [columnName]="'status'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('claim_number')"
        >
          Client Case Number
          <app-table-column-sort
            [columnName]="'claim_number'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('client')"
        >
          Client
          <app-table-column-sort
            [columnName]="'client'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('claimant_name')"
        >
          Subject Name
          <app-table-column-sort
            [columnName]="'claimant_name'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('provider_name')"
        >
          Provider Name
          <app-table-column-sort
            [columnName]="'provider_name'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('invoice_total')"
        >
          Invoice Total
          <app-table-column-sort
            [columnName]="'invoice_total'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('total')"
        >
          Total Paid
          <app-table-column-sort
            [columnName]="'total'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
        <th
          class="border-0 cursor-pointer"
          scope="col"
          (click)="sortColumn('created_on')"
        >
          Created On
          <app-table-column-sort
            [columnName]="'created_on'"
            [orderBy]="invoiceListOrderBy"
            [orderDirection]="invoiceListOrderDirection"
          ></app-table-column-sort>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="invoices?.length; else showNoInvoice">
      <ng-container *ngFor="let invoice of invoices">
        <tr class="bg-white">
          <td>
            <a
              [routerLink]="['/invoice-pdf', invoice.id]"
              target="_blank"
              class="cursor-pointer primary-text font-weight-semibold"
              >{{ invoice.invoice_number }}</a
            >
          </td>
          <td>
            <a
              [routerLink]="[
                '/limos',
                invoice.claim_number,
                invoice.limos_case_number
              ]"
              target="_blank"
              class="cursor-pointer primary-text font-weight-semibold"
              >{{ invoice.limos_case_number }}</a
            >
          </td>
          <td>{{ invoice.status }}</td>
          <td>{{ invoice.claim_number }}</td>
          <td>{{ invoice.client }}</td>
          <td>{{ invoice.claimant_name }}</td>
          <td>{{ invoice.provider_name }}</td>

          <td>{{ invoice.invoice_total | currency }}</td>
          <td>{{ invoice.total | currency }}</td>
          <td>{{ invoice.created_on | clientDateFormat }}</td>
        </tr>
      </ng-container>
    </tbody>
    <ng-template #showNoInvoice>
      <tbody>
        <tr class="bg-white">
          <td colspan="10" class="text-center">Data not available</td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>
<div class="invoice-pagination">
  <div
    class="px-0 py-0 spearPaginationWrapper paginationWrapper d-flex"
    *ngIf="invoices?.length"
  >
    <ngb-pagination
      [(collectionSize)]="invoiceTotal"
      [(page)]="invoicePage"
      [pageSize]="invoiceLimit"
      [maxSize]="10"
      [boundaryLinks]="true"
      (pageChange)="onPageChangeinvoices($event)"
    >
    </ngb-pagination>

    <div class="selected d-flex align-items-center">
      <select
        (change)="onSizeChangeinvoices($event.target.value)"
        class="custom-select table-select h-100"
        [(ngModel)]="invoiceLimit"
      >
        <option [value]="30">30</option>
        <option [value]="50">50</option>
        <option [value]="75">75</option>
        <option [value]="100">100</option>
      </select>

      <p class="mb-0 ml-2 text-nowrap">
        Showing {{ invoicePage * invoiceLimit - invoiceLimit + 1 }} -
        {{
          invoicePage * invoiceLimit > invoiceTotal
            ? invoiceTotal
            : invoicePage * invoiceLimit
        }}
        of {{ invoiceTotal }}
      </p>
    </div>
  </div>
</div>
